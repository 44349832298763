"use strict";/* ==================================================
Mobile nav display toggle:

Toggles animated display of main header nav
on small screens
===================================================*/var $navToggle=$('.nav-toggle');var $body=$('body');$navToggle.click(function(e){e.preventDefault();if($body.hasClass('nav-open')){closeSideNav();}else{openSideNav();}});function openSideNav(){$navToggle.children('.hamburger').addClass('active');$body.addClass('nav-open');}function closeSideNav(){$navToggle.children('.hamburger').removeClass('active');$body.removeClass('nav-open');}/* ==================================================
Back to top link:

Smooth scrolls back to top of page
===================================================*/$('.back-to-top').click(function(){$('html, body').animate({scrollTop:0},600);return false;});$('.smooth-scroll').click(function(event){var target=$(this.hash);event.preventDefault();$('html, body').animate({scrollTop:target.offset().top},600,function(){// Callback after animation
// Must change focus!
var $target=$(target);$target.focus();if($target.is(":focus")){// Checking if the target was focused
return false;}else{$target.attr('tabindex','-1');// Adding tabindex for elements not focusable
$target.focus();// Set focus again
};});});/* ==================================================
Sliders:

Glide setup and initialization
===================================================*/ // if(document.querySelector('.glide')) {
//   var glideMedia = new Glide('.glide', {
//       type: 'carousel',
//       perView: 1,
//       perTouch: 1
//     }).mount();
// }